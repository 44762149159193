//for IE, Edge polyfill
var objectFitImages = require('object-fit-images');

window.jQuery = window.$ = require('jquery');

$(function(){

	objectFitImages(); //for IE, Edge polyfill

	$('#global_nav_button').on('click',function(){
		$('#global_nav_button, #global_nav_menu').toggleClass('open');
    return false;
	});

  $('#global_nav_menu').on('click',function(){
		$('#global_nav_button, #global_nav_menu').removeClass('open');
	});

	$('.plus-icon').on('click',function(){
		var group = this.dataset.group; // data-groupの属性を獲得する
		var dropdown = this.dataset.dropdown; // data-dropdownの属性を獲得する
		$('#globalNav .'+group).not('.'+dropdown).removeClass('open');
		$('#globalNav .'+dropdown).toggleClass('open');
	});

  $(window).scroll(function() {
		var $header = $('#site_header, #global_nav_menu');
    if ($(window).scrollTop() > 144) {
      $header.addClass('fixed');
    } else {
      $header.removeClass('fixed');
    }
  });

});
